

















































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import {imageUrl, showTagRE} from '@/utils/common'
import {ExportExcel} from "@/utils/exportFile";

@Component({})
export default class AUser extends Vue {


  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };

  toJackpot(row: any) {
    this.$router.push({
      name: 'jackpot',
      query:{
        id: row.id,
        lotteryId: row.lotteryId,
        name: row.lotteryName
      }
    })
  }

  toMing(row: any) {
    this.$router.push({
      name: 'prizeList',
      query:{
        id: row.id,
        name: row.lotteryName
      }
    })
  }


  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post('J27701', this.search)
      .then(res => {
        this.total = res.total;
        this.tableData = res.list;
      }).finally(() =>{
      this.loading = false;
    })
  }

  // 编辑
  toEdit(row: any) {
    this.$router.push({
      name: 'prizeDrawEdit',
      query:{
        id: row.id
      }
    })
  }


  setStatus(row: any, val: any) {
    // 1 上线  2 下线 3 删除
    let tips: any = val == 1 ? "是否发布上架?" : '是否下架?'
    this.$confirm( tips , "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      api
              .post('J27703', {id: row.id, status: val})
              .then((res: any) => {
                this.$message.success("操作成功");
                this.searchTable()
              })

    });
  }

  // 添加活动
  add() {
    this.$router.push({
      name: 'prizeDrawEdit'
    })
  }

  activated() {
    this.searchTable();
  }
}
